<template>
  <div class="content">
    <Card title="上传增长参谋参数填写">
      <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="120">
        <FormItem label="选择授权应用:" prop="packageName">
          <Select v-model="formValidate.packageName" filterable placeholder="授权应用">
            <Option v-for="item in allApp" :value="item.app_name" :key="item.app_name">{{item.app}}</Option>
          </Select>
        </FormItem>

        <FormItem label="增长参谋-APPID" prop="csjAppID">
          <Input v-model="formValidate.csjAppID" placeholder="输入穿山甲AppID"/>
        </FormItem>

        <FormItem label="增长参谋-用户ID" prop="user_id">
          <Input v-model="formValidate.user_id" placeholder="输入增长参谋-用户ID"/>
        </FormItem>

        <FormItem label="增长参谋-密钥" prop="secret_key">
          <Input v-model="formValidate.secret_key" placeholder="输入增长参谋-密钥"/>
        </FormItem>

        <FormItem>
          <Row :gutter="16">
            <Col span="8">
              <Button @click="handleReset()" style="margin-left: 8px">重置</Button>
            </Col>
            <Col span="8">
              <Button type="success" @click="handleSubmit('formValidate')">提交</Button>
            </Col>
          </Row>
        </FormItem>
      </Form>
    </Card>
    <Modal
        v-model="isModelSubmit"
        title="确认提交"
        @on-ok="submitOk"
        @on-cancel="submitCancel">
      <List>
        <ListItem>选择授权应用:<b>{{appMap[formValidate.packageName]}}</b></ListItem>
        <ListItem>增长参谋-APPID:<b>{{formValidate.csjAppID}}</b></ListItem>
        <ListItem>增长参谋-用户ID:<b>{{formValidate.user_id}}</b></ListItem>
        <ListItem>增长参谋-密钥:<b>{{formValidate.secret_key}}</b></ListItem>
      </List>
    </Modal>
  </div>
</template>
<script>
import {GetAppList,Upload} from "@/api/upload";

export default {
  name: 'Upload',
  data () {
    return{
      isModelSubmit:false,
      appMap:{},
      allApp:[],
      formValidate: {
        packageName:'',
        csjAppID:'',
        user_id:'',
        secret_key: '',
      },
      ruleValidate: {
        packageName: [
          {required: true, message: '授权应用不能为空', trigger: 'change'}
        ],
        csjAppID: [
          {required: true, message: '穿山甲ID不能为空', trigger: 'blur'}
        ],
        user_id: [
          {required: true, message: '用户ID不能为空', trigger: 'blur'}
        ],
        secret_key: [
          {required: true, message: '密钥不能为空', trigger: 'blur'}
        ],
      }
    }
  },
  created() {
    GetAppList().then(res=>{
      if(res === "获取应用失败"){
        this.$Message.error(res);
      }
      this.allApp = res
      for (let item of this.allApp) {
        this.appMap[item.app_name] = item.app
      }
    })
  },
  methods: {
    handleReset() {
      this.formValidate.packageName = ''
      this.formValidate.csjAppID = ''
    },
    handleSubmit(name){
      this.$refs[name].validate((valid) => {
        if (valid) {
          let csjAppID = parseInt(this.formValidate.csjAppID)
          if(isNaN(csjAppID)){
            this.$Message.error("穿山甲ID填写错误，穿山甲ID是int类型");
            return
          }
          this.isModelSubmit = true
        } else {
          this.$Message.error('Fail!');
        }
      })
    },
    submitCancel(){
      this.isModelSubmit = false
    },
    submitOk(){
      let data = {
        "package_name": this.formValidate.packageName,
        "csj_app_id": parseInt(this.formValidate.csjAppID),
        "user_id":this.formValidate.user_id,
        "secret_key": this.formValidate.secret_key
      }

      Upload(data).then(res=>{
        if(res.data === "操作成功"){
          this.isSubmit = true
          this.$Message.info(res.data);
        }else{
          this.$Message.error(res.data);
        }
      })

    }
  }
}
</script>

<style>
.content{
  text-align: center;
  background-color: #FFFF;
  width: 30%;
  margin: auto;
  top: 15%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index:2;
  position: absolute;
}
b{
  color: #dc2514;
}
</style>