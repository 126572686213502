<template>
  <div class="content" >
    <Card v-if="isLogin === true" title="授权回调参数填写">
      <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="120">
        <FormItem label="选择授权平台:" prop="platform">
          <Select v-model="formValidate.platform" placeholder="授权平台">
            <Option v-for="item in allPlatform" :value="item.value" :key="item.value">{{item.label}}</Option>
          </Select>
        </FormItem>

        <FormItem v-if="formValidate.platform==='kuaishou'" label="授权账号ID:" prop="accountID">
          <Input v-model="formValidate.accountID" placeholder="输入账号ID"></Input>
        </FormItem>

        <FormItem>
          <Row :gutter="16">
            <Col span="8">
              <Button @click="handleReset()" style="margin-left: 8px">重置</Button>
            </Col>
            <Col span="8" v-if="formValidate.platform === 'kuaishou'">
              <Button type="success" @click="handleJump('formValidate')">授权链接跳转</Button>
            </Col>
            <Col span="8" v-if="formValidate.platform === 'toutiao'">
              <Button type="success" @click="handleJump2()">授权链接跳转</Button>
            </Col>
          </Row>
        </FormItem>
      </Form>
    </Card>
    <Modal
        v-model="isModelAuth"
        title="授权链接跳转"
        @on-ok="authOk"
        @on-cancel="authCancel">
      <p v-if="formValidate.platform === 'kuaishou'">
        请确保磁力引擎登录的快手ID是: <b>{{formValidate.accountID}}</b>
      </p>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'Callback',
  data () {
    return {
      isLogin:false,
      platformName:{
        "kuaishou":"快手",
        "toutiao":"头条",
      },
      isModelAuth:false,
      platformMap:{
        "kuaishou":165897661,
        "toutiao":1722532901119006,
      },
      allPlatform:[
        {
          value: 'kuaishou',
          label: '快手'
        },
        {
          value: 'toutiao',
          label: '头条'
        }
      ],
      formValidate: {
        platform: 'kuaishou',
        accountID:'',
      },
      ruleValidate: {
        platform: [
          {required: true, message: '授权平台不能为空', trigger: 'change'}
        ],
        accountID: [
          {required: true, message: '账号ID不能为空', trigger: 'blur'}
        ],
      }
    }
  },
  created() {
    this.isLogin = true
  },
  methods: {
    handleReset () {
      this.formValidate.accountID = ''
    },
    handleJump2(){
      let url = `https://open.oceanengine.com/audit/oauth.html?app_id=` + this.platformMap[this.formValidate.platform]
      url += `&state=your_custom_params&scope=%5B10000000%2C11000000%2C130%2C3%2C4%2C5%2C8%2C9%2C2%2C14%2C112%2C110%2C120%2C122%2C12000000%5D&material_auth=1&redirect_uri=`
      url += `https://mtasks.dev.tagtic.cn/api/marketing/callback?state=`+this.platformMap[this.formValidate.platform]
      window.location.href = url
    },
    handleJump(name){
      this.$refs[name].validate((valid) => {
        if (valid) {
          let accountId = parseInt(this.formValidate.accountID)
          if(isNaN(accountId)){
            this.$Message.error("授权账号ID填写错误，授权账号ID是int类型");
            return
          }
          this.isModelAuth = true
        } else {
          this.$Message.error('Fail!');
        }
      })
    },
    authOk(){
      let url = `https://developers.e.kuaishou.com/tools/authorize?app_id=` + this.platformMap[this.formValidate.platform]
      url += `&scope=%5B%22ad_query%22%2C%22ad_manage%22%2C%22report_service%22%2C%22account_service%22%2C%22public_dmp_service%22%2C%22public_agent_service%22%2C%22public_account_service%22%5D&redirect_uri=`
      url += `https://mtasks.dev.tagtic.cn/api/marketing/callback/ks&state={"app_id":`+this.platformMap[this.formValidate.platform] + ","
      url += `"account_id":`+this.formValidate.accountID+"}"
      window.location.href = url
    },
    authCancel(){
      this.isModelAuth = false
    },
  },
}
</script>

<style>
.content{
  text-align: center;
  background-color: #FFFF;
  width: 30%;
  margin: auto;
  top: 15%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index:2;
  position: absolute;
}
b{
  color: #dc2514;
}
</style>
