import {axios} from '@/libs/api.request';

export const GetAppList = () =>{
  return axios.request({
    url: '/app/list',
    method: 'get',
  });
}

export const Upload = (data) => {
  return axios.request({
    url: '/upload',
    data: data,
    method: 'post',
  });
}

export const Login = (data) => {
  return axios.request({
    url: '/login',
    data: data,
    method: 'post',
  });
}