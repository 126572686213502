import axios from 'axios'
import global from "@/libs/global"

axios.defaults.withCredentials = true// Cookie跨域

// 导出请求类
export default class HttpRequest {
    interceptors(instance){
        // 添加响应拦截器
        instance.interceptors.response.use(res => {
            if(res.headers.authorization !== undefined){
                // 每次请求会带上Authorization
                global.setToken(res.headers.authorization)
            }
            // 处理返回对象,取res中的data参数对应的值
            const {data} = res;
            return data;

        }, error => {
            return Promise.reject(error);
        });
    }

    // 设置默认请求参数
    defaultHeaders() {
        return {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
                // 'Content-Type': 'application/json;charset=UTF-8'
            }
        };
    }

    // axios 请求，method，url，data
    request(options) {
        // 创建 axios请求
        const instance = axios.create({
            baseURL:"https://mtask-job.xg.tagtic.cn/"
        });

        // 覆盖默认
        options = Object.assign(this. defaultHeaders(), options);

        // 请求处理
        this.interceptors(instance)

        // 发送请求
        return instance(options)
    }
}
