<template>
  <div class="content" >
    <Card title="登录">
      <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="120">
        <FormItem label="用户名:" prop="user_name">
          <Input v-model="formValidate.user_name" placeholder="用户名"></Input>
        </FormItem>

        <FormItem label="密 码:" prop="password">
          <Input v-model="formValidate.password" placeholder="密码"></Input>
        </FormItem>

        <FormItem>
          <Row :gutter="16">
            <Col span="8">
              <Button @click="handleReset()" style="margin-left: 8px">重置</Button>
            </Col>
            <Col span="8">
              <Button type="success" @click="handleSubmit('formValidate')">提交</Button>
            </Col>
          </Row>
        </FormItem>
      </Form>
    </Card>
  </div>
</template>

<script>
import {Login} from "@/api/upload";
export default {
  name: 'Login',
  data () {
    return {
      formValidate: {
        user_name: '',
        password:'',
      },
      ruleValidate: {
        user_name: [
          {required: true, message: '用户名不能为空', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '密码不能为空', trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    handleReset () {
      this.formValidate.user_name = ''
      this.formValidate.password = ''
    },
    handleSubmit(name){
      this.$refs[name].validate((valid) => {
        if (valid) {
          let data = {
            "user_name": this.formValidate.user_name,
            "password": this.formValidate.password
          }
          Login(data).then(res=>{
            if(res.err_no > 0){
              this.$Message.error(res.err_msg)
            } else{
              this.$router.push({name: 'callback'})
            }
          })
        } else {
          this.$Message.error('Fail!');
        }
      })
    },
  },
}
</script>

<style>
.content{
  text-align: center;
  background-color: #FFFF;
  width: 30%;
  margin: auto;
  top: 15%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index:2;
  position: absolute;
}
b{
  color: #dc2514;
}
</style>
